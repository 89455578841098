import { useEffect, useState } from 'react';
import { RequestState } from '../constants/request-state';
import { dealsService } from '../services/deals.service';
import { SettlementInstructions } from '../types/amr-pipeline/models/SettlementInstructions';

interface Props {
    dealReferenceName: string;
    transactionReferenceName: string;
    classReferenceName: string;
}

export const useSettlementInstructions = ({
    dealReferenceName,
    transactionReferenceName,
    classReferenceName,
}: Props) => {
    const [requestState, setRequestState] = useState(RequestState.none);
    const [settlementInstructions, setSettlementInstructions] = useState<SettlementInstructions>();

    useEffect(() => {
        (async () => {
            try {
                setRequestState(RequestState.request);
                const response = await dealsService.getSettlementInstructions(
                    dealReferenceName,
                    transactionReferenceName,
                    classReferenceName,
                );
                setSettlementInstructions(response);
                setRequestState(RequestState.success);
            } catch (e) {
                setRequestState(RequestState.failure);
            }
        })();
    }, [classReferenceName, dealReferenceName, transactionReferenceName]);

    return { settlementInstructions, requestState };
};
