import { useDispatch } from "react-redux";
import { errorActions } from "../actions";
import { onBoardingTooltipService } from "../services";
import { OnBoardingTooltipType } from "../types/onboarding-tooltips/OnBoardingTooltipType";
import { user } from "../user";

export const useSetOnBoardingTooltipCountRequest = () => {
    const dispatch = useDispatch();

    const setCount = async (count: number, type: OnBoardingTooltipType) => {
        try {
            user.setOnBoardingTooltipCounter(type, count);
            await onBoardingTooltipService.setCount([
                {
                    page: type,
                    count,
                },
            ]);
            user.setOnBoardingTooltipCounter(type, count);
        } catch (e) {
            dispatch(errorActions.error(e));
        }
    };

    return { setCount };
};
