import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { tradeAllocationTemplateActions } from '../../actions/trade-allocation-template.actions';
import { AppState } from '../../types/state/AppState';
import { shouldRequest } from '../../utils';

export function useTradeAllocationTemplates(disabled: boolean = false) {
    const dispatch = useDispatch();
    const { requestState, items } = useSelector((s: AppState) => s.entities.tradeAllocationTemplate);

    React.useEffect(() => {
        if (!disabled && shouldRequest(requestState)) {
            dispatch(tradeAllocationTemplateActions.request());
        }
    }, [requestState, disabled, dispatch]);

    return { tradeAllocationTemplatesRequestState: requestState, templates: items };
}
