import { useDispatch } from 'react-redux';
import { isRequestNone } from '../../utils';
import { daysService } from '../../services';
import { errorActions } from '../../actions';
import { useEffect, useState } from 'react';
import { RequestState } from '../../constants/request-state';
import { Moment } from 'moment';

export function useNextBusinessDates(from: Moment, daysCount: number = 10) {
    const dispatch = useDispatch();
    const [requestStateFetchNextBusinessDates, setRequestStateFetchNextBusinessDates] = useState(RequestState.none);
    const [nextBusinessDates, setNextBusinessDates] = useState<Date[]>([]);

    useEffect(() => {
        if (isRequestNone(requestStateFetchNextBusinessDates)) {
            (async () => {
                try {
                    setRequestStateFetchNextBusinessDates(RequestState.request);
                    const nextBusinessDates = await daysService.getNextBusinessDates(from, daysCount);
                    setNextBusinessDates(nextBusinessDates);
                    setRequestStateFetchNextBusinessDates(RequestState.success);
                } catch (e) {
                    setRequestStateFetchNextBusinessDates(RequestState.failure);
                    dispatch(errorActions.error(e));
                }
            })();
        }
    }, [requestStateFetchNextBusinessDates, daysCount, from, dispatch]);

    return { nextBusinessDates, requestStateFetchNextBusinessDates };
}
