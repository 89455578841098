import { useEffect, useState } from 'react';
import { isRequestNone } from '../utils';
import { RequestState } from '../constants/request-state';
import { logger } from '../logging/logger';
import { bwicService } from '../services/bwic.service';

export const useBwicWithSecurityExist = (securityId: number, disabled = false) => {
    const [exist, setExist] = useState(false);
    const [requestState, setRequestState] = useState(RequestState.none);

    useEffect(() => {
        async function request() {
            try {
                const existRequest = await bwicService.isBwicWithSecuritesExist([securityId]);
                setExist(existRequest);
                setRequestState(RequestState.success);
            } catch(e) {
                logger.exception(e as Error, 'Failed to request exist bwics with securities');
                setRequestState(RequestState.failure);
            }
        }

        if (isRequestNone(requestState) && !disabled) {
            setRequestState(RequestState.request);
            request();
        }
    }, [requestState, securityId, disabled]);

    useEffect(() => {
        // Reset state if securityId is changed
        setRequestState(RequestState.none);
        setExist(false);
    }, [securityId]);

    const result: [requestState: RequestState, exist: boolean] = [requestState, exist];

    return result;
};
