import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { errorActions } from '../../actions';
import { RequestState } from '../../constants/request-state';
import { Company } from '../../types/amr-pipeline/models/Company';
import { AppState } from '../../types/state/AppState';
import { isRequestNone } from '../../utils';
import { amrCompaniesService } from '../../services/amr-companies.service';
import { availableBrokerDealersActions } from '../../actions/entities/availableBrokerDealers.actions';

export const useAvailableBrokerDealers = () => {
    const dispatch = useDispatch();
    const { requestState, items } = useSelector((state: AppState) => state.entities.availableBrokerDealers);

    const getCompanies = useCallback(async () => {
        try {
            dispatch(availableBrokerDealersActions.request());
            const companies: Company[] = await amrCompaniesService.getAvailableBrokerDealers();
            dispatch(availableBrokerDealersActions.requestResult(RequestState.success, companies));
        } catch (e) {
            dispatch(errorActions.unexpectedError(e));
            dispatch(availableBrokerDealersActions.requestResult(RequestState.failure, []));
        }
    }, [dispatch]);

    useEffect(() => {
        if (isRequestNone(requestState)) {
            getCompanies();
        }
    }, [requestState, dispatch, getCompanies]);

    return { requestState, items };
};
