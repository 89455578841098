import { useEffect, useState } from 'react';
import { RequestState } from '../constants/request-state';
import { AmrDocument } from '../types/amr-pipeline/models/AmrDocument';
import { dealsService } from '../services/deals.service';

interface Props {
    dealReferenceName?: string;
    transactionReferenceName?: string;
}

export const useAmrDocuments = ({ dealReferenceName, transactionReferenceName }: Props) => {
    const [requestState, setRequestState] = useState(RequestState.none);
    const [amrDocuments, setAmrDocuments] = useState<AmrDocument[]>();

    useEffect(() => {
        (async () => {
            if (!dealReferenceName || !transactionReferenceName) {
                return;
            }

            try {
                setRequestState(RequestState.request);
                const response = await dealsService.getAmrDocument(dealReferenceName, transactionReferenceName);
                setAmrDocuments(response);
                setRequestState(RequestState.success);
            } catch (e) {
                setRequestState(RequestState.failure);
            }
        })();
    }, [dealReferenceName, transactionReferenceName]);

    return { amrDocuments, requestState };
};
