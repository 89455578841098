import { useHistory } from 'react-router';
import { useEffect, useRef } from 'react';
import { Location, UnregisterCallback } from 'history';

export const useHistoryListener = (onChange: () => void, disabled: boolean = false) => {
    const history = useHistory();
    const prevLocation = useRef<Location<unknown> | null>(null);

    const isPathChanged = (nextLocation: Location<unknown>) =>
        !prevLocation.current ||
        !nextLocation ||
        prevLocation.current.pathname !== nextLocation?.pathname;

    useEffect(() => {
        let unListen: UnregisterCallback | null = null;

        if (disabled) {
            unListen = history.listen(location => {
                if (isPathChanged(location)) {
                    onChange();
                }
                prevLocation.current = location
            })
        }

        return () => {
            unListen && unListen()
        }
    }, [history, disabled, onChange])
}
