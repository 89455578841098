import { useState, useEffect } from 'react';
import { RequestState } from '../../constants/request-state';
import { isRequestNone } from '../../utils';
import { CompanyWithMembers } from '../../types/amr-pipeline/models/Company';
import { amrCompaniesService } from '../../services/amr-companies.service';

export function useCompany(referenceName: string) {
    const [requestState, setRequestState] = useState(RequestState.none);
    const [companyWithMembers, setCompanyWithMembers] = useState<CompanyWithMembers | null>(null);

    useEffect(() => {
        if (isRequestNone(requestState)) {
            const doRequest = async () => {
                setRequestState(RequestState.request);
                try {
                    const [company, members] = await Promise.all([
                        amrCompaniesService.getCompanyInfo(referenceName),
                        amrCompaniesService.getMembersList(referenceName),
                    ]);
                    setCompanyWithMembers({ ...company, members });
                    setRequestState(RequestState.success);
                } catch (e) {
                    setRequestState(RequestState.failure);
                }
            };

            doRequest();
        }
    }, [requestState, referenceName]);

    return { companyWithMembers, requestState };
}
