import { locationService } from '../../services';
import { constants } from '../../constants';
import { useCallback, useEffect, useState } from 'react';
import { State } from '../../types/location/State';
import { useDispatch } from 'react-redux';
import { errorActions } from '../../actions';
import { RequestState } from '../../constants/request-state';
import { isRequesting } from '../../utils';

export const useStates = () => {
    const dispatch = useDispatch();
    const [states, setStates] = useState<State[]>([]);
    const [requestState, setRequestState] = useState(RequestState.none);

    const getStates = useCallback(async () => {
        try {
            setRequestState(RequestState.request)
            const states = await locationService.getStates(constants.USCountryCode)
            setStates(states)
            setRequestState(RequestState.success)
        } catch (e) {
            setRequestState(RequestState.failure)
            dispatch(errorActions.unexpectedError(e))
        }
    }, [dispatch])

    useEffect(() => {
        if(!isRequesting(requestState)) {
            getStates()
        }
    }, [requestState, getStates])

    return { states, requestState };
}
