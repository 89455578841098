import * as React from 'react';
import { useDispatch } from 'react-redux';
import { settlementAgentAgreementActions } from '../actions/settlement-agent-agreements.actions';
import { roles } from '../constants';
import { user } from '../user';
import { isRequestNone } from '../utils';
import { RequestState } from '../constants/request-state';
import { settlementAgentAgreementService } from '../services/settlement-agent-agreement.service';
import { errorActions } from '../actions';
import { useAppSelector } from './useAppSelector';

let _requestState = RequestState.none;

export function resetSettlementAgentAgreementsRequestState() {
    _requestState = RequestState.none;
}

export const useSettlementAgentAgreements = () => {
    const dispatch = useDispatch();

    const items = useAppSelector(s => s.entities.settlementAgentAgreements.items);
    const requestState = useAppSelector(s => s.entities.settlementAgentAgreements.requestState);

    React.useEffect(() => {
        const shouldRequest =
            isRequestNone(_requestState) &&
            user.hasRoles(...roles.seller(), ...roles.bd()) &&
            !user?.current()?.isClearingBank;

        if (shouldRequest) {
            (async function () {
                try {
                    _requestState = RequestState.request;
                    dispatch(settlementAgentAgreementActions.request());
                    const agreements = await settlementAgentAgreementService.getAgreements();
                    dispatch(settlementAgentAgreementActions.requestResult(RequestState.success, agreements));
                    _requestState = RequestState.success;
                } catch (e) {
                    _requestState = RequestState.failure;
                    dispatch(settlementAgentAgreementActions.requestResult(RequestState.failure));
                    dispatch(errorActions.unexpectedError(e));
                }
            })();
        }
    }, [dispatch])

    return { items, requestState };
};


