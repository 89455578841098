import { useCallback, useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { locationService } from '../../services';
import { City } from '../../types/location/City';
import { errorActions } from '../../actions';
import { RequestState } from '../../constants/request-state';
import { isRequesting } from '../../utils';

export const useCities = (stateId: number) => {
    const dispatch = useDispatch();
    const currentStateId = useRef(stateId)

    const [cities, setCities] = useState<City[]>([]);
    const [requestState, setRequestState] = useState(RequestState.none);

    const getCities = useCallback(async () => {
        if (stateId && (currentStateId.current !== stateId || !isRequesting(requestState))) {
            currentStateId.current = stateId;
            try {
                setRequestState(RequestState.request)
                const cities = await locationService.getCities(stateId)
                setCities(cities)
                setRequestState(RequestState.success)
            } catch (e) {
                setRequestState(RequestState.failure)
                dispatch(errorActions.unexpectedError(e))
            }
        }
    }, [stateId, dispatch, requestState])

    useEffect(() => {
        getCities()
    }, [getCities]);

    return { cities }
}
