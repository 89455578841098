import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { settlementAccountActions } from '../../actions/settlement-account.actions';
import { AppState } from '../../types/state/AppState';
import { shouldRequest } from '../../utils';
import { user } from '../../user';
import { roles } from '../../constants';

export function useSettlementAccounts(requestEnabled = true) {
    const dispatch = useDispatch();
    const { requestState, items } = useSelector((s: AppState) => s.entities.settlementAccount);

    React.useEffect(() => {
        if (requestEnabled && shouldRequest(requestState) && (user.hasRoles(...roles.seller()) || user.current()!.isClearingBank)){
            dispatch(settlementAccountActions.request());
        }
    }, [requestState, requestEnabled, dispatch]);

    return { settlementAccountsRequestState: requestState, settlementAccounts: items };
}
