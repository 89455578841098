import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { errorActions } from '../actions';
import { RequestState } from '../constants/request-state';
import { Webinar } from '../types/marketing/Webinar';
import { releaseService } from '../services/release.service';
import { isRequestNone } from '../utils';

export const useGetWebinar = (disabled: boolean = false) => {
    const dispatch = useDispatch();

    const [webinar, setWebinar] = useState<Webinar>();
    const [requestStateGetWebinar, setRequestStateGetWebinar] = useState(RequestState.none);

    const getData = useCallback(async () => {
        try {
            setRequestStateGetWebinar(RequestState.request);
            const data = await releaseService.fetchWebinar();
            setWebinar(data);
            setRequestStateGetWebinar(RequestState.success);
        } catch (e) {
            dispatch(errorActions.unexpectedError(e));
            setRequestStateGetWebinar(RequestState.failure);
        }
    }, [dispatch])

    useEffect(() => {
        if(!disabled && isRequestNone(requestStateGetWebinar)) {
            getData()
        }
    }, [disabled, getData, requestStateGetWebinar])

    return { requestStateGetWebinar, webinar }
}
