import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../types/state/AppState';
import { shouldRequest } from '../utils';
import { brokerDealerUserListActions } from '../actions/broker-dealer-user-list.actions';

export const useBrokerDealerUsers = () => {
    const dispatch = useDispatch();

    const items = useSelector((state: AppState) => state.entities.brokerDealerUserList.items);
    const requestState = useSelector((state: AppState) => state.entities.brokerDealerUserList.requestState);

    useEffect(() => {
        if (shouldRequest(requestState)) {
            dispatch(brokerDealerUserListActions.request())
        }
    }, [dispatch, requestState]);

    return { requestState, items }
};
