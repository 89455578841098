import { useEffect, useState } from "react";
import { RequestState } from "../constants/request-state";
import { isRequesting } from "../utils";
import { dealsService } from "../services/deals.service";
import { Transaction } from "../types/amr-pipeline/models/Transaction";

export function useDealTransactions(
    dealReferenceName: string,
    isRequestAllowed: boolean
) {
    const [requestState, setRequestState] = useState(RequestState.none);
    const [dealTransactions, setDealTransactions] = useState<Transaction[]>();

    useEffect(() => {
        if (!isRequestAllowed || isRequesting(requestState)) {
            return;
        }

        const doRequest = async () => {
            setRequestState(RequestState.request);
            try {
                const dealDetails: Transaction[] =
                    await dealsService.getDealTransactions(dealReferenceName);
                setDealTransactions(dealDetails);
                setRequestState(RequestState.success);
            } catch (e) {
                setRequestState(RequestState.failure);
            }
        };

        doRequest();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRequestAllowed]);

    return { dealTransactions, requestState };
}

