import { useEffect } from "react";
import { useAppSelector } from "../useAppSelector";
import { PushHistoryStateItemType } from "../../types/state/PushHistoryState";
import { useDispatch } from "react-redux";
import { notificationActions } from "../../actions/notification.actions";
import { BwicBase } from "../../types/bwic/BwicBase";
import dateTimeUtils from "../../utils/dateTime.utils";
import { constants } from "../../constants";

export function useBiddingCompletedSoonNotification(...accepted: BwicBase[]){
    const dispatch = useDispatch();

    const reminderPush = useAppSelector(s =>
        s.pushHistory.history[PushHistoryStateItemType.BiddingCompleteReminder]?.at(-1)
    );

    const showNotification = (bwic: BwicBase) => {
        const bidsDue = dateTimeUtils.utcToLocalString(bwic.bidsDueUtc ?? bwic.bidsDueDateUtc);
        const goodUntil = dateTimeUtils.utcToLocalString(bwic.goodUntilUtc ?? bwic.goodUntilDateUtc, constants.timeFormat);
        const title = `BWIC ends in ${reminderPush?.payload.remainingMinutes} minutes. <span class="name-tag">${bidsDue}, ${bwic.name}</span>`;
        const message = `The BWIC is scheduled to end at <b>${goodUntil}</b>. Please note, bids will not be firm past <b>${goodUntil}</b>. However, the system will not force the end of the BWIC.`
        const blinkingBrowserTabText = [
            document.title.toString(),
            `BWIC ends in ${reminderPush?.payload.remainingMinutes} minutes`
        ];

        dispatch(notificationActions.infoModalReplace(bwic.referenceName, message, title, blinkingBrowserTabText, 'bidding-completed-popup'));
    }

    useEffect(() => {
        if (reminderPush != null) {
            const target = accepted.find(
                b => b &&
                b.referenceName === reminderPush.bwicReferenceName
            );

            if (target) {
                showNotification(target);
            }
        }
        // eslint-disable-next-line
    }, [reminderPush]);
}