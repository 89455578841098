import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { shouldRequest } from '../../utils';
import { AppState } from '../../types/state/AppState';
import { rejectedTradeActions } from '../../actions/entities/rejected-trades.actions';

export function useRejectedTrades(disabled: boolean = false) {
    const dispatch = useDispatch();
    const { requestState, items } = useSelector((s: AppState) => s.entities.rejectedTrades);

    useEffect(() => {
        if (shouldRequest(requestState) && !disabled) {
            dispatch(rejectedTradeActions.request());
        }
    }, [requestState, disabled, dispatch]);

    return { requestStateGetTrades: requestState, rejectedTrades: items };
}
