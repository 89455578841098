import { useEffect } from "react";
import { isRequestNone } from "../utils/request-state.utils";
import { useAppSelector } from "./useAppSelector";
import { useDispatch } from "react-redux";
import { amrPipelineCommonActions } from "../actions";

export function useImUserCompany() {
    const dispatch = useDispatch();
    const imUserCompany = useAppSelector(
        (s) => s.issuanceMonitor.amrPipelineCommon.userCompany
    );
    const imUserCompanyRequestState = useAppSelector(
        (s) => s.issuanceMonitor.amrPipelineCommon.requestState
    );

    useEffect(() => {
        if (isRequestNone(imUserCompanyRequestState)) {
            dispatch(amrPipelineCommonActions.loadUserCompanyRequest());
        }
    }, [dispatch, imUserCompanyRequestState]);

    return { imUserCompany, imUserCompanyRequestState };
}
