import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../types/state/AppState';
import { shouldRequest } from '../utils';
import { portfolioListActions } from "../actions/portfolio-list.actions";

export const usePortfolios = (readOnly: boolean = false) => {
    const dispatch = useDispatch();
    const items = useSelector((state: AppState) => state.entities.portfolios.items);
    const requestState = useSelector((state: AppState) => state.entities.portfolios.requestState);

    useEffect(() => {
        if (shouldRequest(requestState) && !readOnly) {
            dispatch(portfolioListActions.request());
        }
    }, [dispatch, requestState, readOnly]);
    return { items, requestState };
}
