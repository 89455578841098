import { useCallback, useEffect, useState } from 'react';
import { RequestState } from '../../constants/request-state';
import { locationService } from '../../services';
import { Country } from '../../types/location/Country';

export const useCountries = () => {
    const [requestState, setRequestState] = useState(RequestState.none);
    const [countries, setCountries] = useState<Country[]>([]);

    const getCountries = useCallback(async () => {
        try {
            setRequestState(RequestState.request);
            const countries = await locationService.getCountries();
            setCountries(countries)
            setRequestState(RequestState.success);
        } catch (e) {
            setRequestState(RequestState.failure);
        }
    }, [])

    useEffect(() => {
        getCountries()
    }, [getCountries])


    return {countries, requestState}
}
