import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { tradeAllocationActions } from '../../actions/trade-allocation.actions';
import { RequestState } from '../../constants/request-state';
import { AppState } from '../../types/state/AppState';
import { shouldRequest } from '../../utils/request-state.utils';

export function useTradeAllocation(tradeId: string, disabled: boolean = false) {
    const dispatch = useDispatch();
    const { requestState, tradeAllocationItems } = useSelector((s: AppState) => s.entities.tradeAllocation.items[tradeId])
        ?? { requestState: RequestState.none, tradeAllocationItems: [] };

    React.useEffect(() => {
        if (shouldRequest(requestState) && !disabled) {
            dispatch(tradeAllocationActions.request(tradeId));
        }
    }, [tradeId, requestState, disabled, dispatch])

    return { requestState, tradeAllocationItems };
}
