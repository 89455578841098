import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isRequestNone } from '../utils';
import { RequestState } from '../constants/request-state';
import { activeInventorySecurityActions } from '../actions/active-inventory-security.actions';
import { AppState } from "../types/state/AppState";

export const useActiveInventorySecuritesIds = (disabled = false) => {
    const dispatch = useDispatch();

    const activeSecuriesState = useSelector((s: AppState) => s.entities.activeInventorySecurities);

    useEffect(() => {
        if (isRequestNone(activeSecuriesState.requestState) && !disabled) {
            dispatch(activeInventorySecurityActions.requestActiveInventorySecurities());
        }
    }, [dispatch, activeSecuriesState.requestState, disabled]);

    const result: [requestState: RequestState, securitiesByInventoryCount: { [securityId: number]: number }] =
        [activeSecuriesState.requestState, activeSecuriesState.items];

    return result;
};
