import { useCallback, useEffect, useState } from 'react';
import { RequestState } from '../constants/request-state';
import { bwicService } from '../services';
import { useDispatch } from 'react-redux';
import { errorActions } from '../actions';
import { shouldRequest } from '../utils';
import { AllBwicsTab } from '../components/allBWICS/AllBwicsTab';
import { user } from '../user';
import { roles } from '../constants';

export const useMyBwicsExist = (activeTab: AllBwicsTab, myBwicsOnly: boolean, isAllToAll?: boolean): [boolean, RequestState] => {
    const dispatch = useDispatch();
    const [requestStateIsMyBwicsExist, setRequestStateIsMyBwicsExist] = useState(RequestState.none);
    const [myBwicsExist, setMyBwicsExist] = useState(false);
    const isBdBuy = activeTab === AllBwicsTab.Buy && user.hasRoles(...roles.bd());

    const getBwicsExist = useCallback(async (isBdBuy: boolean) => {
        try {
            setRequestStateIsMyBwicsExist(RequestState.request)
            const exist = await bwicService.myBwicsExist(isBdBuy);
            setMyBwicsExist(exist)
            setRequestStateIsMyBwicsExist(RequestState.success)
        } catch (e) {
            dispatch(errorActions.criticalError(e))
            setRequestStateIsMyBwicsExist(RequestState.failure)
        }
    }, [dispatch])

    useEffect(() => {
        setRequestStateIsMyBwicsExist(RequestState.none);
    }, [isBdBuy])

    useEffect(() => {
        if (shouldRequest(requestStateIsMyBwicsExist) && (myBwicsOnly || isAllToAll)) {
            getBwicsExist(isBdBuy)
        }
    }, [getBwicsExist, myBwicsOnly, isAllToAll, requestStateIsMyBwicsExist, isBdBuy])

    return [myBwicsExist, requestStateIsMyBwicsExist]
}
