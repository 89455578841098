import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../types/state/AppState';
import { shouldRequest } from '../utils';
import { brokerDealerContactListActions } from '../actions/broker-dealer-contact-list.actions';

export const useBrokerDealerContacts = () => {
    const dispatch = useDispatch();

    const items = useSelector((state: AppState) => state.entities.brokerDealerContactList.items);
    const requestState = useSelector((state: AppState) => state.entities.brokerDealerContactList.requestState);

    useEffect(() => {
        if (shouldRequest(requestState)) {
            dispatch(brokerDealerContactListActions.request())
        }
    }, [dispatch, requestState]);

    return { requestState, items }
};
