import { RefObject, useState, useCallback, useLayoutEffect } from "react";

export const useResizeObserver = (ref: RefObject<HTMLElement>, callback?: (entry: DOMRectReadOnly) => void) => {
    const [width, setWidth] = useState<number>();
    const [height, setHeight] = useState<number>();
    const handleResize = useCallback(
        (entries: ResizeObserverEntry[]) => window.requestAnimationFrame(() => {
            if (!Array.isArray(entries)) {
                return;
            }

            const entry = entries[0];
            setWidth(entry.contentRect.width);
            setHeight(entry.contentRect.height);

            if (callback) {
                callback(entry.contentRect);
            }
        }),
        [callback],
    );

    useLayoutEffect(() => {
        if (!ref.current) {
            return;
        }

        let RO = new ResizeObserver((entries: ResizeObserverEntry[]) => handleResize(entries));
        RO.observe(ref.current);

        return () => {
            RO.disconnect();
        };
    }, [ref, handleResize]);

    return [width, height];
};
