import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isRequestNone } from '../../utils/request-state.utils';
import { AppState } from '../../types/state/AppState';
import { emailPreferencesActions } from '../../actions/email-preferences.actions';

export function useEmailPreferences() {
    const dispatch = useDispatch();
    const { requestState, preferences } = useSelector((s: AppState) => s.entities.emailPreferences);

    React.useEffect(() => {
        if (isRequestNone(requestState)) {
            dispatch(emailPreferencesActions.request());
        }
    }, [requestState, dispatch]);

    return { requestState, preferences };
}
