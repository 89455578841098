import { useEffect, useRef } from 'react';
import { isEqual } from 'lodash';
import { SORTING_TYPE, sortingOptions, SORT } from '../constants';

interface SortRule {
    sortingField: string;
    sortingType: string;
    direction?: string;
}

export function useSortedListByManyFields<T>(
    list: T[],
    setSortedList: (list: T[]) => void,
    sortingField: string = '',
    sortDirection: string = SORT.DESC,
    sortingType: string = SORTING_TYPE.string,
    sortRules: { [field: string]: SortRule[] } = {}
) {
    const lastList = useRef<T[]>([]);
    useEffect(() => {
        let newList = [...list];
        const sortCollection = sortRules[sortingField] || [{ sortingField, sortingType }, ...sortRules.default];
        [...sortCollection].reverse().forEach(s => {
            const direction = s.direction || sortDirection;
            const option = sortingOptions.find(o => (
                    o.type === s.sortingType &&
                    o.direction === direction
                )
            );
            newList.sort((a, b) => {
                return option?.compare(a[s.sortingField as keyof T], b[s.sortingField as keyof T]);
            })
        });

        if (!isEqual(lastList.current, newList)) {
            lastList.current = newList;
            setSortedList(newList);
        }
    }, [list, setSortedList, sortingField, sortDirection, sortingType, sortRules])
}
