import * as React from 'react';
import { Company } from '../types/company/Company';
import { RequestState } from '../constants/request-state';
import { companiesService } from '../services/companies.service';
import { shouldRequest } from '../utils/request-state.utils';
import { logger } from '../logging/logger';

export function useCurrentUserCompany() {
    const [company, setCompany] = React.useState<Company>();
    const [requestState, setRequestState] = React.useState(RequestState.none);

    React.useEffect(() => {
        const request = async () => {
            try {
                const company: Company = await companiesService.getCurrentUserCompany();
                setCompany(company);
                setRequestState(RequestState.success);
            } catch (e) {
                logger.exception(e as Error, 'Failed to request current user company');
                setRequestState(RequestState.failure);
            }
        }

        if (shouldRequest(requestState)) {
            setRequestState(RequestState.request);
            request();
        }
    }, [requestState]);

    const result: [requestState: RequestState, company?: Company] = [requestState, company];

    return result;
}
