import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { assetManagerActions } from '../../actions/asset-manager.actions';
import { AppState } from '../../types/state/AppState';
import { shouldRequest } from '../../utils';

export function useAssetManagers() {
    const dispatch = useDispatch();
    const { requestState, items } = useSelector((s: AppState) => s.entities.assetManager);

    React.useEffect(() => {
        if (shouldRequest(requestState)) {
            dispatch(assetManagerActions.request());
        }
    }, [requestState, dispatch]);

    return { assetManagersRequestState: requestState, assetManagers: items };
}
