import { useAgreement } from "../components/seller-buyside/useAgreement";
import { roles } from "../constants";
import { SettlementAgreementStatus } from "../types/bid-as-dealer/SettlementAgreementStatus";
import { user } from "../user";

export function useAllToAllAgreementBlocked() {
    const { agreement } = useAgreement();
    const isAgreementConfirmed = agreement?.agreementStatus === SettlementAgreementStatus.confirmed;
    const isBdTrader = user.hasRoles(roles.BrokerDealerTrader);
    return isBdTrader && !isAgreementConfirmed && !user?.current()?.isClearingBank;
}