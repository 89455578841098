import { useEffect } from "react";
import { useAppSelector } from "./useAppSelector";
import { PushHistoryStateItemType } from "../types/state/PushHistoryState";
import { useDispatch } from "react-redux";
import { notificationActions } from "../actions";
import { BwicProcessType } from "../types/models/Process";
import { BwicBase } from "../types/bwic/BwicBase";
import { dateTimeUtils } from "../utils";
import { pushHistoryActions } from "../actions/entities/push-history.actions";
import { BwicPositionBase } from "../types/bwic/BwicPositionBase";

export enum RecipientType {
    Seller,
    Bidder
}

type BiddingCompletedNotificationBwic = BwicBase & {
    securities: BwicPositionBase[];
}

export function useBiddingCompletedNotification(
    recipient: RecipientType = RecipientType.Bidder,
    ...accepted: BiddingCompletedNotificationBwic[]) {

    // BFF 
    useNotification(
        PushHistoryStateItemType.BwicStage1Ended,
        accepted,
        recipient,
        BwicProcessType.BestFootForward);

    // Live, TopX, JumpBall
    useNotification(
        PushHistoryStateItemType.BwicStage2Ended,
        accepted,
        recipient,
        BwicProcessType.Live,
        BwicProcessType.JumpBall,
        BwicProcessType.TopX
    );
}

function useNotification(
    pushType: PushHistoryStateItemType,
    accepted: BiddingCompletedNotificationBwic[],
    recipient: RecipientType,
    ...processTypes: BwicProcessType[]) {
    const dispatch = useDispatch();

    const push = useAppSelector(s => s.pushHistory.history[pushType]?.at(-1));

    const formatTickers = (securities: BwicPositionBase[]) =>
        `Securities from BWIC: <ul class="list-bullet grey">${securities.map(s => "<li>" + s.ticker + "</li>").join("")}</ul>`

    const showBiddingCompletedNotification = (bwic: BiddingCompletedNotificationBwic) => {
        const bidsDue = dateTimeUtils.utcToLocalString(bwic.bidsDueUtc ?? bwic.bidsDueDateUtc);
        const title = `Bidding period is completed <span class="name-tag">${bidsDue}, ${bwic.name}</span>`;
        const message = recipient === RecipientType.Bidder
            ? 'No bids are accepted. BWIC is in the Bidding status until Seller completes the BWIC manually.'
            : bwic.process.type === BwicProcessType.BestFootForward
                ? 'Bidding period is completed. Bidders will no longer be able to improve their bids, however, you will be able to trade and send color.'
                : 'Stage 2 is completed. Bidders will no longer be able to improve their bids, however, you will be able to trade and send color.';
        const tabText = [document.title.toString(), 'Bidding period is completed'];

        dispatch(notificationActions.infoModalReplace(
            bwic.referenceName,
            `<div class="margin-b-8">${message}</div>` + formatTickers(bwic.securities),
            title,
            tabText,
            'bidding-completed-popup')
        );
    }

    useEffect(() => {
        if (push != null) {
            const target = accepted.find(b => b &&
                b.referenceName === push.bwicReferenceName &&
                processTypes.some(p => p === b.process.type)
            );
            if (target) {
                showBiddingCompletedNotification(target);
                dispatch(pushHistoryActions.resetForType(pushType));
            }
        }
        // eslint-disable-next-line
    }, [push]);
}