import { useEffect } from "react";
import { errorActions } from "../../actions";
import { arrangersPipelineActions } from "../../actions/entities/arrangers-pipeline.actions";
import { RequestState } from "../../constants/request-state";
import { amrArrangerPipelineService } from "../../services/amr-arranger-pipeline.service";
import { isRequestNone } from "../../utils";
import { useAppDispatch } from "../useAppDispatch";
import { useAppSelector } from "../useAppSelector";
import { SubscriptionFeature } from "../../types/billing/SubscriptionFeature";
import { user } from "../../user";

export function useSecurityArrangersPipeline(tickers: string[]) {
    const dispatch = useAppDispatch();
    const requestState = useAppSelector(s => s.entities.latestTransaction.requestState);


    useEffect(() => {
        const requiredFeatures = [
            SubscriptionFeature.IssuanceMonitorArrangerPipeline,
            SubscriptionFeature.CanLookupArrangersPipelineFromPortfolio
        ];
    
        if (!isRequestNone(requestState) || !tickers.length || !user.hasAllFeatures(...requiredFeatures)) return;

        const doRequest = async () => {
            dispatch(arrangersPipelineActions.request(tickers));

            try {
                const transactions = await amrArrangerPipelineService.getArrangerPipelinesByTicker(tickers);
                dispatch(arrangersPipelineActions.response(RequestState.success, transactions));
            } catch (e) {
                dispatch(arrangersPipelineActions.response(RequestState.failure));
                dispatch(errorActions.error(e));
            }
        }

        doRequest();
        // eslint-disable-next-line
    }, [JSON.stringify(tickers), dispatch, requestState])

}