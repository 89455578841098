import {useEffect } from 'react';

// Prevents IE from navigating back on backspace button click
export const useBackspaceRedirectBlocker = () => {
    const handleKeyDown = e => {
        if (e.keyCode === 8) {
            const tagName = e.target.tagName && e.target.tagName.toLowerCase();
            const editable = (tagName === 'input' || tagName === 'textarea') && e.target.contentEditable;

            if (!editable || e.target.disabled || e.target.readOnly) {
                e.preventDefault();
            }
        }
    }

    useEffect(() => {
        if (document.documentMode) { // IE
            document.addEventListener('keydown', handleKeyDown);
            return () => document.removeEventListener('keydown', handleKeyDown);
        }
    }, []);
}
