import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { user } from '../user';
import { favoriteBrokerDealersActions } from '../actions/favorite-broker-dealers.actions';
import { AppState } from '../types/state/AppState';
import { isRequestNone } from '../utils/request-state.utils';
import { roles } from '../constants';

export const useFavorites = (disabled = false) => {
    const dispatch = useDispatch();
    const favorites = useSelector((state: AppState) => state.favoriteBrokerDealers.favorites);
    const requestState = useSelector((state: AppState) => state.favoriteBrokerDealers.requestState);
    useEffect(() => {
        if (user.hasRoles(roles.SellerTrader) && isRequestNone(requestState) && !disabled) {
            dispatch(favoriteBrokerDealersActions.fetchFavoriteBrokerDealersRequest())
        }
    }, [dispatch, favorites, requestState, disabled]);
    return { requestState, favorites }
};
