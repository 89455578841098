import { useEffect } from "react";
import { RequestState } from "../../constants/request-state";
import { isRequestNone } from "../../utils";
import { useDispatch } from "react-redux";
import { liveBiddingActions } from "../../actions/entities/live-bidding.actions";
import { biddingService } from "../../services/bidding.service";
import { LiveBiddingPosition } from "../../types/live-bidding/LiveBiddingPosition";
import { useAppSelector } from "../useAppSelector";

let _requestState = RequestState.none;

export function resetLiveBiddingDataRequestState() {
    _requestState = RequestState.none;
}

export function useLiveBiddingData(positionId: number) {
    const dispatch = useDispatch();

    const requestState = useAppSelector(s => s.entities.liveBidding.requestState);
    const data =  useAppSelector(s => s.entities.liveBidding.items.find(i => i.positionId === positionId));

    useEffect(() => {
        if (isRequestNone(_requestState)) {
            (async function () {
                _requestState = RequestState.request;
                dispatch(liveBiddingActions.request());
                try {
                    const positions: LiveBiddingPosition[] = await biddingService.getLiveBiddingData();
                    _requestState = RequestState.success;
                    dispatch(liveBiddingActions.response(positions, RequestState.success));
                } catch {
                    dispatch(liveBiddingActions.response([], RequestState.failure));
                    _requestState = RequestState.failure
                }
            })();
        }
    }, [dispatch])

    return { requestState, data };
}