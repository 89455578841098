import { useEffect, useRef } from 'react';
import { onBoardingTooltipConst } from '../constants';
import { ObjectUtils } from '../utils/object.utils';

export function useCacheOnBoardingState(step, stateBoardingTooltip) {
    const currentState = useRef({});
    useEffect(() => {
        if (step === 1 && !ObjectUtils.equal(stateBoardingTooltip, currentState.current)) {
            currentState.current = stateBoardingTooltip;
            localStorage.setItem(onBoardingTooltipConst.localStorageKey, JSON.stringify(currentState.current))
        } // eslint-disable-next-line
    }, [stateBoardingTooltip]);
}
