import { useEffect } from "react";
import { useAppDispatch } from "../useAppDispatch";
import { errorActions } from "../../actions";
import { latestTransactionActions } from "../../actions/entities/latest-transaction.actions";
import { RequestState } from "../../constants/request-state";
import { useAppSelector } from "../useAppSelector";
import { isRequestNone } from "../../utils";
import { amrPipelineService } from "../../services/amr-pipeline.service";

export function useSecurityLatestTransaction(tickers: string[]) {
    const dispatch = useAppDispatch();
    const requestState = useAppSelector(s => s.entities.latestTransaction.requestState);


    useEffect(() => {
        if (!isRequestNone(requestState) || !tickers.length) return;

        const doRequest = async () => {
            dispatch(latestTransactionActions.request(tickers));

            try {
                const transactions = await amrPipelineService.getLatestTransactionsByTicker(tickers);
                dispatch(latestTransactionActions.response(RequestState.success, transactions));
            } catch (e) {
                dispatch(latestTransactionActions.response(RequestState.failure));
                dispatch(errorActions.error(e));
            }
        }

        doRequest();
        // eslint-disable-next-line
    }, [JSON.stringify(tickers), dispatch, requestState])
}