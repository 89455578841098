import { useEffect, useRef, useState } from "react";
import { roles } from "../../constants";
import { isRequestFailed, isRequestNone, isRequestSuccess } from "../../utils";
import { InventoryPosition } from "../../types/inventory/InventoryPosition";
import { RequestState } from "../../constants/request-state";
import { user } from "../../user";
import { inventoryService } from "../../services/inventory.service";
import { InventoryPositionStatus } from "../../types/inventory/InventoryPositionStatus";
import { isEqual } from "lodash";
import { Rating } from "../../types/enums/Rating";

export function useInvenotory(disabled?: boolean, statuses?: InventoryPositionStatus[], ...tickers: string[]) {
    const bd = useBdInventory(disabled, statuses, tickers);
    const all = useAllInventory(disabled, statuses ?? [], tickers ?? []);

    if (user.hasRoles(...roles.bd())) return bd;
    if (user.hasRoles(...roles.inventoryAccess())) return all;
    return { requestState: RequestState.success, inventory: [] };
}

function useBdInventory(disabled?: boolean, statuses?: InventoryPositionStatus[], tickers?: string[]) {
    const [requestState, setRequestState] = useState(RequestState.none);
    const [inventory, setInventory] = useState<InventoryPosition[]>([]);

    useEffect(() => {
        if (!disabled && isRequestNone(requestState) && user.hasRoles(...roles.bd())) {
            const doRequest = async () => {
                setRequestState(RequestState.request);
                try {
                    const inventory = await inventoryService.getBdInventory();
                    setInventory(inventory.inventorySecurities);
                    setRequestState(RequestState.success);
                } catch (e) {
                    setRequestState(RequestState.failure);
                }
            }

            doRequest();
        }
    }, [requestState, disabled])

    return {
        requestState,
        inventory: inventory
            .filter(x => !tickers?.length || tickers.some(t => x.ticker.localeCompare(t, undefined, { sensitivity: 'accent' }) === 0))
            .filter(x => !statuses?.length || statuses.some(s => x.status === s))
    };
}

type TFilter = {
    identifiers: string[];
    statuses: InventoryPositionStatus[];
    ratings: Rating[];
    dealers: number[] | null;
};

function useAllInventory(disabled?: boolean, statuses: InventoryPositionStatus[] = [], tickers: string[] = []) {
    const [requestState, setRequestState] = useState(RequestState.none);
    const [inventory, setInventory] = useState<InventoryPosition[]>([]);
    const lastAppliedFilter = useRef<TFilter | null>(null);

    useEffect(() => {
        if (disabled || !user.hasRoles(...roles.inventoryAccess())) return;

        // Reset state if filter changed
        const filter: TFilter = { identifiers: tickers, statuses, ratings: [], dealers: null };
        if (
            lastAppliedFilter.current != null &&
            (isRequestSuccess(requestState) || isRequestFailed(requestState)) &&
            !isEqual(filter, lastAppliedFilter.current)) {
            setRequestState(RequestState.none);
            setInventory([]);
        }

        if (isRequestNone(requestState)) {
            const doRequest = async () => {
                setRequestState(RequestState.request);
                try {
                    const inventory = await inventoryService.getAllInventory({
                        ...filter,
                        pageSize: 200 // 200 is max allowed page size
                    });
                    lastAppliedFilter.current = { ...filter };
                    setInventory(inventory.result);
                    setRequestState(RequestState.success);
                } catch (e) {
                    setRequestState(RequestState.failure);
                }
            }

            doRequest();
        }
    }, [requestState, tickers, statuses, disabled])

    return { requestState, inventory };
}
