import { useEffect, useState } from 'react';
import { BrokerDealerCompanySlim } from '../types/company/BrokerDealerCompanySlim';
import { FavoriteCompanyState } from '../types/state/FavoriteBrokerDealersState';
import { CompanyStatus } from '../types/company/CompanyStatus';
import { Contact } from '../types/company/Contact';
import { UserSlim } from '../types/account/UserSlim';
import { DealerListFilterOption } from '../types/state/DealerListState';
import { DealerFilterOptions } from '../components/dealer-list/DealerFilterOptions';
import { SettlementAgentAgreement } from '../types/bid-as-dealer/SettlementAgentAgreement';

const searchContact = (contact: Contact, searchTerm: string) =>
    `${contact.firstName} ${contact.lastName}`.toLowerCase().includes(searchTerm) ||
    (contact?.role && contact?.role.toLowerCase().includes(searchTerm)) ||
    contact.email.toLowerCase().includes(searchTerm);

const searchUser = (user: UserSlim, searchTerm: string) =>
    `${user.firstName} ${user.lastName}`.toLowerCase().includes(searchTerm) ||
    user.email.toLowerCase().includes(searchTerm);

export const useDealersFilter = (
    dealersList: BrokerDealerCompanySlim[],
    contacts: Contact[],
    users: UserSlim[],
    searchTerm: string,
    filterOption: DealerListFilterOption[],
    favoriteDealers: FavoriteCompanyState,
    agreements?: SettlementAgentAgreement[],
    disabled?: boolean
) => {
    const [dealers, setDealers] = useState(dealersList);

    useEffect(() => {
        if (disabled) return;

        const searchTermLowerCase = searchTerm.trim().toLowerCase();
        const isAnyOptionSelected = filterOption.some(o => o.selected);
        const isFavorites = filterOption?.some(o => o.value === DealerFilterOptions.Favorite && o.selected);
        const isPlatform = filterOption?.some(o => o.value === DealerFilterOptions.Platform && o.selected);
        const isOffPlatform = filterOption?.some(o => o.value === DealerFilterOptions.OffPlatform && o.selected);

        const newList = dealersList.filter(dealer => {
            const companyMatch = dealer.name.toLowerCase().includes(searchTermLowerCase);
            const distributionListMatch = dealer.distributionList && dealer.distributionList.toLowerCase().includes(searchTermLowerCase);
            const headOfTradingMatch = dealer.headOfTrading && dealer.headOfTrading?.email?.toLowerCase().includes(searchTermLowerCase);
            const dealerContactsOccurrences = contacts.filter(c => c.companyId === dealer.id && searchContact(c, searchTermLowerCase));
            const dealerUsersOccurrences = users.filter(u => u.companyId === dealer.id && searchUser(u, searchTermLowerCase));

            let bySearchTerm = true;
            let byOption = true;

            if (searchTermLowerCase) {
                bySearchTerm = companyMatch || distributionListMatch || headOfTradingMatch || !!dealerContactsOccurrences.length || !!dealerUsersOccurrences.length;
            }

            if (isAnyOptionSelected) {
                const returnFavorites = !!(isFavorites && favoriteDealers[dealer.id]);
                const returnPlatform = !!(isPlatform && dealer.status === CompanyStatus.active);
                const returnOffPlatform = !!(isOffPlatform && dealer.status !== CompanyStatus.active);
                byOption = returnFavorites || returnPlatform || returnOffPlatform;
            }

            return bySearchTerm && byOption;
        });

        setDealers(newList);
    }, [dealersList, contacts, users, favoriteDealers, filterOption, disabled, searchTerm, agreements]);

    return { dealers };
};
