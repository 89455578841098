import { RefObject, useState } from 'react';
import { useResizeObserver } from './useResizeObserever';

const PIPELINE_FILTER_ROW_HEIGHT = 32;

export const useFilterNextLineHide = (parentRef: RefObject<HTMLElement>, filtersRefs: RefObject<HTMLElement[]>, isFilterCollapsed: boolean, shouldShowHideButtonCallback: (shouldShow: boolean) => void) => {
    const [countOfHiddenFilters, setCountOfHiddenFilters] = useState(0);

    useResizeObserver(parentRef, (entry: DOMRectReadOnly) => {
        shouldShowHideButtonCallback(entry.height > PIPELINE_FILTER_ROW_HEIGHT);
        const topDimensionFilterContainer = parentRef?.current?.getBoundingClientRect().top || 0;
        let numberOfHiddenFilters = 0;

        filtersRefs?.current?.map(el => {
            const topDimensionFilterItem = el?.getBoundingClientRect().top;

            // if filter not visible
            if (topDimensionFilterItem === 0) {
                numberOfHiddenFilters++
            }

            if (topDimensionFilterItem > topDimensionFilterContainer) {
                numberOfHiddenFilters++;
                isFilterCollapsed ? el.classList.add('display-none') : el.classList.remove('display-none');
            }

            setCountOfHiddenFilters(numberOfHiddenFilters);
            return el;
        });
    });

    return countOfHiddenFilters
};
