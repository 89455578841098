import * as React from 'react';
import { useHistory } from 'react-router';

export function useLocationStateReset() {
    const history = useHistory();

    React.useEffect(() => {
        if (history.location.state) {
            history.replace({ ...history.location, state: undefined });
        }
        // eslint-disable-next-line
    }, []);
}
