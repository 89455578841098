import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearingBankActions } from '../../actions';
import { RequestState } from '../../constants/request-state';
import { SettlementAgentCommissionByRating } from '../../types/bid-as-dealer/SettlementAgentCommissionByRating';
import { AppState } from '../../types/state/AppState';
import { isRequestNone } from '../../utils';

export function useSettlementAgentDefaultCommission(companyId: number, disabled = false) {
    const dispatch = useDispatch();
    const { requestState, values } = useSelector((s: AppState) => s.entities.settlementAgentCommission.commission[companyId]) ?? {
        requestState: RequestState.none, values: {} as SettlementAgentCommissionByRating
    };

    React.useEffect(() => {
        if (isRequestNone(requestState) && !disabled) {
            dispatch(clearingBankActions.settlementAgentCommissionRequest(companyId));
        }
    }, [requestState, companyId, disabled, dispatch])

    return { requestState, values };
}
