import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { errorActions } from '../actions';
import { RequestState } from '../constants/request-state';
import { companiesService } from '../services';
import { CompanyMember } from '../types/company/CompanyMember';

export const useCompanyUserList = (companyId: number) => {
    const dispatch = useDispatch();

    const [requestStateGetUsers, setRequestState] = useState(RequestState.none);
    const [users, setUsers] = useState<CompanyMember[]>([]);

    const getUsers = useCallback(async () => {
        try {
            setRequestState(RequestState.request);
            const users = await companiesService.getCompanyMembers(companyId);
            setUsers(users);
            setRequestState(RequestState.success);
        } catch (e) {
            setRequestState(RequestState.failure);
            dispatch(errorActions.error(e))
        }
    }, [companyId, dispatch])

    useEffect(() => {
        getUsers();
    }, [companyId, getUsers]);

    return { requestStateGetUsers, users };
}
