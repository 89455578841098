import { useEffect, useState } from 'react';
import { RequestState } from '../constants/request-state';
import { dealsService } from '../services/deals.service';
import { ExcludedDealer } from '../types/amr-pipeline/models/ExcludedDealer';

interface Props {
    dealReferenceName: string;
    transactionReferenceName: string;
    classReferenceName: string;
}

export const useExcludedDealers = ({ dealReferenceName, transactionReferenceName, classReferenceName }: Props) => {
    const [requestState, setRequestState] = useState(RequestState.none);
    const [excludedDealers, setExcludedDealers] = useState<ExcludedDealer[]>([]);

    useEffect(() => {
        (async () => {
            try {
                setRequestState(RequestState.request);
                const response = await dealsService.getExcludedDealers(
                    dealReferenceName,
                    transactionReferenceName,
                    classReferenceName,
                );
                setExcludedDealers(response);
                setRequestState(RequestState.success);
            } catch (e) {
                setRequestState(RequestState.failure);
            }
        })();
    }, [classReferenceName, dealReferenceName, transactionReferenceName]);

    return { excludedDealers, requestState };
};
