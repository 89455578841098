import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { errorActions } from "../../actions";
import { trusteeActions } from "../../actions/entities/trustee.actions";
import { RequestState } from "../../constants/request-state";
import { Company } from "../../types/amr-pipeline/models/Company";
import { AppState } from "../../types/state/AppState";
import { isRequestNone } from "../../utils";
import { amrCompaniesService } from "../../services/amr-companies.service";
import { partiesActions } from "../../actions/entities/parties.actions";
import { CompanyStatus } from "../../types/amr-pipeline/enums/CompanyStatus";

const visibleStatuses = [CompanyStatus.Active, CompanyStatus.OffPlatform];

export const useParties = () => {
    const dispatch = useDispatch();
    const { requestState, items } = useSelector(
        (state: AppState) => state.entities.parties
    );

    const getCompanies = useCallback(async () => {
        try {
            dispatch(partiesActions.request());

            const [
                arrangerCompanies,
                trusteeCompanies,
                collateralManagerCompanies,
                majorityEquityCompanies,
                amrAgentCompanies,
            ] = await Promise.all<Array<Company[]>>([
                amrCompaniesService.getArrangersList(false, visibleStatuses),
                amrCompaniesService.getTrusteeList(visibleStatuses),
                amrCompaniesService.getCollateralManagerList(visibleStatuses),
                amrCompaniesService.getMajorityEquityList(visibleStatuses),
                amrCompaniesService.getAmrAgentList(visibleStatuses),
            ]);

            dispatch(
                partiesActions.requestResult(RequestState.success, {
                    arrangerCompanies,
                    trusteeCompanies,
                    collateralManagerCompanies,
                    majorityEquityCompanies,
                    amrAgentCompanies,
                })
            );
        } catch (e) {
            dispatch(errorActions.unexpectedError(e));
            dispatch(trusteeActions.requestResult(RequestState.failure));
        }
    }, [dispatch]);

    useEffect(() => {
        if (isRequestNone(requestState)) {
            getCompanies();
        }
    }, [requestState, dispatch, getCompanies]);

    return { requestState, items };
};

