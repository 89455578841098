import { useEffect, useState } from 'react';
import { sortingOptions, SORT } from '../constants';

export function useSortedList(list, sortBy, sortDirection, sortingType, enabled = true, customSortingCompareCallback) {
    const shouldSort = enabled && sortBy && list.length;
    const [newList, setList] = useState(shouldSort ? list : []);

    useEffect(() => {
        let compareCallback = null;

        const resolveSortByPath = obj => sortBy
            .split('.')
            .reduce((o, p) => o ? o[p] : undefined, obj)

        try {
            if (shouldSort) {
                if (sortingType) {
                    const option = sortingOptions.find(o => o.type === sortingType && o.direction === sortDirection);
                    if (option) compareCallback = (a, b) => option.compare(resolveSortByPath(a), resolveSortByPath(b), a, b)
                } else {
                    if (typeof list[0][sortBy] === 'string') {
                        compareCallback = (a, b) =>
                            sortDirection === SORT.ASC
                                ? (a[sortBy] || '').localeCompare(b[sortBy])
                                : (b[sortBy] || '').localeCompare(a[sortBy])
                    } else {
                        compareCallback = (a, b) =>
                            sortDirection === SORT.ASC
                                ? a[sortBy] - b[sortBy]
                                : b[sortBy] - a[sortBy]
                    }
                }
            }

            if (compareCallback && customSortingCompareCallback) {
                const sortedList = [...list].sort((a,b) => customSortingCompareCallback(a,b) || compareCallback(a,b));
                setList(sortedList);
            } else if (compareCallback) {
                const sortedList = [...list].sort(compareCallback);
                setList(sortedList);
            } else if (customSortingCompareCallback) {
                const sortedList = [...list].sort(customSortingCompareCallback)
                setList(sortedList);
            }
        } catch (e) {
            console.log(e);
        }

        
    }, [list, sortBy, sortDirection, sortingType, enabled, customSortingCompareCallback, shouldSort]);

    return shouldSort ? [newList] : [list];
}
