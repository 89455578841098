import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { errorActions } from '../actions';
import { RequestState } from '../constants/request-state';
import { feedbackService } from '../services';

export const useRequestUserFeedback = (requestBody, requestState, setRequestState) => {
    const dispatch = useDispatch();
    useEffect(() => {
        async function request() {
            if (requestState === RequestState.request) {
                try {
                    await feedbackService.sendFeedback(requestBody);
                    setRequestState(RequestState.success);
                } catch (e) {
                    dispatch(errorActions.unexpectedError(e));
                    setRequestState(RequestState.failure);
                }
            }
        }

        request(); // eslint-disable-next-line
    }, [requestState]);
};
