import { useEffect, useRef } from 'react';
import { contactsService } from "../services";

export const useRequestInviteBuysideClients = (emailList, sending, setSending, setSent, setError) => {
    const mounted = useRef(true);

    useEffect(() => {
        mounted.current = true;
        return () => mounted.current = false;  // eslint-disable-next-line
    }, []);

    useEffect(() => {
        async function request() {
            if (sending) {
                try {
                    await contactsService.inviteBuysideClients(emailList);
                    if (mounted.current) {
                        setSending(false);
                        setSent(true);
                        setError(null);
                    }
                } catch (e) {
                    if (mounted.current) {
                        setSending(false);
                        setSent(false);
                        setError(e && e.message);
                    }
                }
            }
        }

        request(); // eslint-disable-next-line
    }, [sending]);
};
