import { useEffect, useRef } from 'react';
import { useFormikContext } from 'formik';

export function useFocusOnError({ fieldRef, name, enable = true }) {
    const formik = useFormikContext();
    const prevSubmitCountRef = useRef(formik.submitCount);
    const nameList = getNameList(formik.errors);
    const firstErrorKey = nameList[0];
    useEffect(() => {
        if ((prevSubmitCountRef.current !== formik.submitCount || formik.isSubmitting) && !formik.isValid && enable) {
            if (fieldRef.current && firstErrorKey === name) fieldRef.current.focus();
        }
        prevSubmitCountRef.current = formik.submitCount; // eslint-disable-next-line
    }, [formik.submitCount, formik.isValid, firstErrorKey]);
}

function getNameList(x) {
    const names = [];
    const keys = Object.keys(x);
    for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        const value = x[key];
        if (value) {
            if (typeof value === 'object') {
                const childPathList = getNameList(value);
                names.push(...childPathList.map(c => `${key}.${c}`));
            } else {
                names.push(key);
            }
        }
    }
    return names;
}
