import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { errorActions } from '../../actions';
import { trusteeActions } from '../../actions/entities/trustee.actions';
import { RequestState } from '../../constants/request-state';
import { Company } from '../../types/amr-pipeline/models/Company';
import { AppState } from '../../types/state/AppState';
import { isRequestNone } from '../../utils';
import { amrCompaniesService } from '../../services/amr-companies.service';

export const useTrustee = () => {
    const dispatch = useDispatch();
    const { requestState, items } = useSelector((state: AppState) => state.entities.trustee);

    const getTrustee = useCallback(async () => {
        try {
            dispatch(trusteeActions.request());
            const trustee: Company[] = await amrCompaniesService.getTrusteeList();
            dispatch(trusteeActions.requestResult(RequestState.success, trustee));
        } catch (e) {
            dispatch(errorActions.unexpectedError(e));
            dispatch(trusteeActions.requestResult(RequestState.failure, []));
        }
    }, [dispatch])

    useEffect(() => {
        if (isRequestNone(requestState)) {
            getTrustee()
        }
    }, [requestState, dispatch, getTrustee]);

    return { requestState, items, }
}
