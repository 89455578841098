import { useEffect } from "react";
import { RequestState } from "../../constants/request-state";
import { isRequestNone } from "../../utils";
import { amrCompaniesService } from '../../services/amr-companies.service';
import { useDispatch, useSelector } from "react-redux";
import { collateralManagersActions } from "../../actions/entities/collateralManagers.action";
import { AppState } from "../../types/state/AppState";
import { Company } from "../../types/amr-pipeline/models/Company";

export function useCollateralManagers() {
    const dispatch = useDispatch();
    const { requestState, items } = useSelector((state: AppState) => state.entities.collateralManagers);

    useEffect(() => {
        if (isRequestNone(requestState)) {
            const doRequest = async () => {
                dispatch(collateralManagersActions.requestCollateralManagers(RequestState.request));
                try {
                    const managers: Company[]  = await amrCompaniesService.getManagersList();
                    dispatch(collateralManagersActions.responseCollateralManagers(RequestState.success, managers));
                } catch (e) {
                    dispatch(collateralManagersActions.requestCollateralManagers(RequestState.failure));
                }
            };

            doRequest();
        }
    }, [requestState, dispatch]);

    return {
        requestState,
        items,
    }
}
