import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../types/state/AppState';
import { isRequestNone } from '../utils';
import { priceTalksActions } from '../actions/price-talks.actions';
import { RequestState } from '../constants/request-state';

export const usePriceTalks = (bwicReferenceName: string, positionId: number, enabled = true) => {
    const dispatch = useDispatch();

    const { requestState, items } = useSelector((s: AppState) => s.entities.priceTalks.priceTalks[positionId])
        ?? { requestState: RequestState.none, items: [] };

    useEffect(() => {
        if (enabled &&
            bwicReferenceName &&
            positionId &&
            isRequestNone(requestState)) {
            dispatch(priceTalksActions.request(bwicReferenceName, positionId));
        }
    }, [enabled, bwicReferenceName, positionId, dispatch, requestState]);

    useEffect(() => {
        return () => {
            dispatch(priceTalksActions.resetFailed());
        };
    }, [dispatch]);

    return { requestState, priceTalks: items };
};
