import { useEffect, useRef, useState } from "react";

const timeout = 1700;

export function useBrowserTabBlinkAnimation(text: string[]) {
    const original = useRef(document.title);
    const [index, setIndex] = useState(0);

    if (text.length) {
        document.title =  document.hidden ? text[index] : original.current;
    }

    useEffect(() => {
        if (text.length) {
            setTimeout(
                () => setIndex(index === text.length - 1 ? 0 : index + 1),
                timeout)
        }
        // eslint-disable-next-line
    }, [index]);


    useEffect(()=> {
        return () => {
            // eslint-disable-next-line
            document.title = original.current
        }
    }, [])
}