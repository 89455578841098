import { useEffect, useState } from "react";
import { RequestState } from "../constants/request-state";
import { accountService } from "../services";
import { AssetManager } from "../types/company/AssetManager";

export const useAssetManagers = () => {
    const [assetManagersRequestState, setAssetManagersRequestState] = useState(RequestState.none);
    const [assetManagers, setAssetManagers] = useState<AssetManager[]>([]);

    useEffect(() => {
        (async () => {
            try {
                setAssetManagersRequestState(RequestState.request);
                const response = await accountService.getAssetManagers();
                setAssetManagers(response);
                setAssetManagersRequestState(RequestState.success);
            } catch (e) {
                setAssetManagersRequestState(RequestState.failure);
            }
        })();
    }, [])

    return { assetManagers, assetManagersRequestState };
}