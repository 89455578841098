import { useEffect, useState } from "react";
import { bwicService } from "../../services";
import { BwicStatus } from "../../types/enums/BwicStatus";
import { BwicSearchResult } from "../../types/bwic-monitor/BwicSearchResult";
import { RequestState } from "../../constants/request-state";
import { isRequestNone } from "../../utils";

export function useBwicsSearch(tickers: string[], statuses: BwicStatus[], disabled?: boolean){
    const [requestState, setRequestState] = useState(RequestState.none);
    const [bwics, setBwics] = useState<BwicSearchResult[]>([]);

    useEffect(() => {
        if (!disabled && tickers?.length && isRequestNone(requestState)) {
            const doRequest = async () => {
                setRequestState(RequestState.request);
                try {
                    const bwics = await bwicService.searchBwics({
                        isinCusipsAndTickers: tickers,
                        statuses: statuses ?? [],
                        pageSize: 200 // 200 is max allowed page size
                    });
                    setBwics(bwics.result);
                    setRequestState(RequestState.success);
                } catch (e) {
                    setRequestState(RequestState.failure);
                }
            }

            doRequest();
        }
    }, [requestState, tickers, statuses, disabled])

    return { bwics, requestState };
}