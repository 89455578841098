import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RequestState } from "../../constants/request-state";
import { AppState } from "../../types/state/AppState";
import { isRequestNone } from "../../utils";
import { cloSystemInfrastructureActions } from "../../actions/entities/cloSystemInfrastructure";
import { cloManagersService } from "../../services/clo-managers.service";
import { CloSystemInfrastructures } from "../../types/clo-managers/CloSystemInfrastructures";
import { groupCloSystemInfrastructure } from "../../utils/clo-managers.utils";

export function useCloSystemInfrastructure() {
    const dispatch = useDispatch();
    const { requestState, items } = useSelector((state: AppState) => state.entities.cloSystemInfrastructure);

    const cloSystemInfrastructureItems = useMemo(() => groupCloSystemInfrastructure(items), [items]);

    useEffect(() => {
        if (isRequestNone(requestState)) {
            const doRequest = async () => {
                dispatch(cloSystemInfrastructureActions.requestData(RequestState.request));
                try {
                    const cloSystemInfrastructure: CloSystemInfrastructures[] = await cloManagersService.getCloSystemInfrastructures();
                    dispatch(cloSystemInfrastructureActions.responseData(RequestState.success, cloSystemInfrastructure));
                } catch (e) {
                    dispatch(cloSystemInfrastructureActions.requestData(RequestState.failure));
                }
            };

            doRequest();
        }
    }, [requestState, dispatch]);

    return {
        requestState,
        cloSystemInfrastructureItems
    };
}
